(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('StickyHeader', [], factory);
    } else {
        // Browser globals
        root.StickyHeader = factory();
    }
}(this, function () {
    'use strict';

    function Header(element) {
        this.container = element;
        if (!this.container) return;
        this.page = document.querySelector('body');

        this.threshold = isNaN(parseInt(this.container.dataset.threshold))
            ? 50
            : parseInt(this.container.dataset.threshold);

        this.onscroll = this.onscroll.bind(this);
        this.onmenuopen = this.onmenuopen.bind(this);
        this.onmenuclose = this.onmenuclose.bind(this);
        window.addEventListener('scroll', this.onscroll);
        document.addEventListener('navigation-menu:open', this.onmenuopen);
        document.addEventListener('navigation-menu:close', this.onmenuclose);

        this.prev = this.threshold;
    }

    Header.prototype = {
        onscroll: function () {
            var top = this.page.getBoundingClientRect().top + this.threshold;

            if (top > 0 && this.container.classList.contains('-sticky')) {
                this.container.classList.remove('-sticky')
            }

            if (top <= 0 && !this.container.classList.contains('-sticky')) {
                this.container.classList.add('-sticky')
            }

            if (top > this.prev && this.container.classList.contains('-sticky')) {
                this.container.classList.add('-visible')
            }

            if (top <= this.prev && this.container.classList.contains('-visible')) {
                this.container.classList.remove('-visible')
            }

            this.prev = top;
        },
        onmenuopen: function (event) {
            this.container.classList.add('-menu-open');
        },
        onmenuclose: function (event) {
            this.container.classList.remove('-menu-open');
        }
    }

    return Header;

}));
