(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('StoreApp', ['Vue', 'Vuex', 'StoreFinder', 'VueSelect'], factory);
    } else {
        // Browser globals
        root.StoreApp = factory(root.Vue, root.Vuex, root.StoreFinder, root.VueSelect);
    }
}(this, function (Vue, Vuex, StoreFinder, VueSelect) {
    'use strict';

    Vue.use(Vuex);
    Vue.component('v-select', VueSelect.VueSelect)

    function configObjectFromMeta(prefix, container) {
        var config = {}

        var meta = container.querySelectorAll('meta[itemprop^="' + prefix + ':"]');

        Array.prototype.forEach.apply(meta, [function (el) {
            var key = el.getAttribute('itemprop').split(':');
            var value = el.getAttribute('content');
            config[key[1]] = value;
        }]);

        return config;
    }

    function App(element) {
        this.container = element;

        var endpoints = configObjectFromMeta('endpoints', this.container);

        this.instance = new Vue({
            el: this.container,
            store: new Vuex.Store(StoreFinder.store),
            name: 'StoreApp',
            render: function (h) {
                return h(StoreFinder.StoreFinder, {
                    props: {
                        provincesEndpoint: endpoints.provinces,
                        nearestEndpoint: endpoints.nearest,
                        byProvinceEndpoint: endpoints.byProvince
                    }
                })
            }
        });
    }

    return App;
}));
