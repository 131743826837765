(function () {
    'use strict';

    if (document.readyState === 'interactive') {
        setup();
    } else {
        document.addEventListener('DOMContentLoaded', setup);
    }

    function setup() {
        // image object-fit
        // https://codepen.io/yurch-html/pen/jwoMWE
        if ('objectFit' in document.documentElement.style === false) {
            Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), function (image) {
                (image.runtimeStyle || image.style).background = 'url("' + image.src + '") no-repeat 50%/' + (image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));

                image.src = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'' + image.width + '\' height=\'' + image.height + '\'%3E%3C/svg%3E';
            });
        }

        var browserCheck = window.browserCheck || function () { }
        var loadComponent = window.loadComponent || function () { }
        var loadFont = window.loadFont || function () { }
        var gdprCookieNoticeSetup = window.gdprCookieNoticeSetup || function () { }

        browserCheck('rvpg');
        loadFont();
        loadComponent('contest-app', window.ContestApp);
        loadComponent('store-app', window.StoreApp)
        loadComponent('datepicker-form-field', window.DatepickerFormField);
        loadComponent('navigation-menu', window.NavigationMenu);
        loadComponent('sticky-header', window.StickyHeader);
        loadComponent('user-profile', window.UserProfile);

        // polyfill per la proprietà CSS video object-fit
        //if (document.querySelector('video')) objectFitVideos();

        document.addEventListener('navigation-menu:open', function () {
            document.querySelector('body').classList.add('-menu-open');
            document.querySelector('.header-wrapper').classList.add('-menu-open');
        });

        document.addEventListener('navigation-menu:close', function () {
            document.querySelector('body').classList.remove('-menu-open');
            document.querySelector('.header-wrapper').classList.remove('-menu-open');
        });

        document.addEventListener('voting-contest:user', function (event) {
            var registrationLink = document.getElementById('registrationLink');

            if (registrationLink) {
                if (event.detail && event.detail.isRegistered && !event.detail.isPending) {
                    registrationLink.style.display = 'none';
                }
                else {
                    registrationLink.style.display = 'block';
                }
            }
        });

        //document.addEventListener('submit', function () {
        //    var disableOnSubmit = document.querySelectorAll('[data-disable-onsubmit]');

        //    Array.prototype.forEach.apply(disableOnSubmit, [function (el) {
        //        el.disabled = true;
        //        el.classList.add('-submitting');

        //        document.querySelector('body').classList.add('-submitting');
        //    }]);
        //});

        window.addEventListener('load', function () {
            gdprCookieNoticeSetup();
        });

        document.addEventListener('voting-contest:open-modal', function () {
            document.querySelector('.header-wrapper').classList.add('-voting-contest-modal-open');
            document.querySelector('.footer-wrapper').classList.add('-voting-contest-modal-open');
            document.querySelector('body').classList.add('-voting-contest-modal-open');
        });

        document.addEventListener('voting-contest:dismiss-modal', function () {
            document.querySelector('.header-wrapper').classList.remove('-voting-contest-modal-open');
            document.querySelector('.footer-wrapper').classList.remove('-voting-contest-modal-open');
            document.querySelector('body').classList.remove('-voting-contest-modal-open')
        });
    }
})();
