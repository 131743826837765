(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('UserProfile', ['Vue', 'EventBus'], factory);
    } else {
        // Browser globals
        root.UserProfile = factory(root.Vue, root.EventBus);
    }
}(this, function (Vue, EventBus) {
    'use strict';

    function Profile(element) {
        this.container = element;
        var user = {};
        var debug = this.container.querySelector('meta[itemprop="debug"]') && true;
        var userMeta = this.container.querySelectorAll('meta[itemprop^="user:"]');

        var logoutUrlMeta = this.container.querySelector('meta[itemprop="logoutUrl"]');
        var loginUrlMeta = this.container.querySelector('meta[itemprop="loginUrl"]');

        Array.prototype.forEach.apply(userMeta, [function (el) {
            var name = el.getAttribute('itemprop').split(':');
            var value = el.getAttribute('content');

            user[name[1]] = value;
        }]);

        this.instance = new Vue({
            el: element,
            name: 'UserProfile',
            template: '<div class="user-profile">'
                + '<span v-if="user">Ciao, {{user.firstname}}!<br /></span>'
                + '<a v-if="user" :href="logoutUrl">Logout</a>'
                + '<a v-else :href="loginUrl">Accedi</a>'
                + '</div>',
            mounted: function () {
                EventBus.$on('voting-contest:user', this.onLogin)
            },
            methods: {
                onLogin: function (data) {
                    this.user = data;
                }
            },
            data: function () {
                return {
                    debug: debug,
                    user: user && user.email ? user : null,
                    loginUrl: loginUrlMeta.getAttribute('content'),
                    logoutUrl: logoutUrlMeta.getAttribute('content')
                }
            }
        })
    }

    return Profile;

}));