/**
 * Impostazioni per https://github.com/passatgt/gdpr-cookie-notice/
 */

function gdprCookieNoticeSetup() {

    if (typeof (window.gdprCookieNotice) !== 'function') return;

    var localeMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:locale"]');
    var currentLocale = localeMeta ? localeMeta.getAttribute('content') : 'en';

    var implicitMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:implicit"]');
    var implicit = implicitMeta ? implicitMeta.getAttribute("content") === 'true' : true;


    var expirationMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:expiration"]');
    var expiration = expirationMeta && !isNaN(parseInt(expirationMeta.getAttribute('content'))) ? parseInt(expirationMeta.getAttribute('content')) : 30;

    var timeoutMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:timeout"]');
    var timeout = timeoutMeta && !isNaN(parseInt(timeoutMeta.getAttribute('content'))) ? parseInt(timeoutMeta.getAttribute('content')) : 500;

    var domainMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:domain"]');
    var currentDomain = domainMeta ? domainMeta.getAttribute('content') : '';

    var statementMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:statement"]');
    var statement = statementMeta ? statementMeta.getAttribute('content') : '';

    var performanceMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:performance"]');
    var performance = performanceMeta ? performanceMeta.getAttribute('content').split(',') : [];

    var analyticsMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:analytics"]');
    var analytics = analyticsMeta ? analyticsMeta.getAttribute('content').split(',') : [];

    var marketingMeta = document.querySelector('meta[itemprop="gdpr-cookie-notice:marketing"]');
    var marketing = marketingMeta ? marketingMeta.getAttribute('content').split(',') : [];


    gdprCookieNoticeLocales.it = {
        description: 'Utilizziamo i cookie per migliorare l’esperienza di navigazione, personalizzare i contenuti e la pubblicità, fornire integrazione con i social network e analizzare il traffico diretto al nostro sito. Potete leggere come utilizziamo i cookie e come controllarli cliccando su Impostazioni. Proseguire nella navigazione su questo sito implica da parte vostra l’accettazione dei cookie.',
        settings: 'Impostazioni',
        accept: 'Accetto',
        statement: 'Informativa sui cookie',
        save: 'Salva impostazioni',
        always_on: 'Sempre Attivi',
        cookie_essential_title: 'Cookie tecnici',
        cookie_essential_desc: 'Cookie temporanei che consentono all’utente di accedere a servizi e a sfruttare le funzionalità del sito. Questi cookie sono necessari al funzionamento del sito.',
        cookie_performance_title: 'Cookie di performance',
        cookie_performance_desc: 'Questi cookie migliorano la funzionalità del nostro sito ma non sono necessari al suo funzionamento.',
        cookie_analytics_title: 'Cookie di analisi',
        cookie_analytics_desc: 'Utilizziamo i cookie di analisi per misurare come gli utenti interagiscono con il nostro sito e migliorare i servizi offerti.',
        cookie_marketing_title: 'Cookie sociali e di marketing',
        cookie_marketing_desc: 'Questi cookie sono utilizzati da terze parti per personalizzare gli annunci pubblicitari secondo gli interessi dell’utente e consentono agli inserzionisti di erogare annunci pubblicitari più interessanti e di maggior valore per gli editori e gli utenti finali.'
    };


    var init = {
        locale: currentLocale,
        timeout: timeout, //Time until the cookie bar appears
        expiration: expiration,
        domain: currentDomain,
        implicit: implicit,
        statement: statement,
        performance: performance, //Cookies in the performance category.
        analytics: analytics, //Cookies in the analytics category.
        marketing: marketing //Cookies in the marketing category.
    };

    gdprCookieNotice(init);
}
