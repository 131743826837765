(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('DatepickerFormField', ['Vue', 'vuejsDatepicker', 'EventBus', 'VueJsDatePickerLanguage'], factory);
    } else {
        // Browser globals
        root.DatepickerFormField = factory(root.Vue, root.vuejsDatepicker, root.EventBus, root.VueJsDatePickerLanguage);
    }
}(this, function (Vue, datePicker, EventBus, VueJsDatePickerLanguage) {

    function PickerField(element) {
        this.container = element;
        this.input = this.container.querySelector('input[type="hidden"]');
        var el = document.createElement('div');
        this.container.appendChild(el);
        var value = this.input.value;

        this.setValue = this.setValue.bind(this);

        var setValue = this.setValue;

        this.instance = new Vue({
            el: el,
            template: '<div><DatePicker v-bind="settings" v-model="value" @selected="onSelected" /></div>',
            components: {
                DatePicker: datePicker
            },
            methods: {
                onSelected: function (date) {
                    if (date) {
                        var m = date.getMonth() + 1;
                        var d = date.getDate();
                        var y = date.getFullYear();
                        m = m < 10 ? '0' + m : m;
                        d = d < 10 ? '0' + d : d;

                        var val = y + '-' + m + '-' + d;

                        setValue(val);

                        EventBus.dispatch('datepicker:change', {
                            instance: this,
                            value: val
                        })

                    }
                }
            },
            computed: {
                settings: function () {
                    var from = new Date();
                    from.setFullYear(from.getFullYear() - 18)
                    return {
                        name: this.name,
                        format: 'dd/MMM/yyyy',
                        typeable: false,
                        'monday-first': true,
                        'placeholder': this.label,
                        language: new VueJsDatePickerLanguage("Italian", ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"], ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"], ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"]),
                        inline: true,
                        disabledDates: { from: from },
                        openDate: this.val ? new Date(this.val) : from
                    }
                }
            },
            data: function () {
                return {
                    value: value
                }
            }
        })
    }

    PickerField.prototype = {
        setValue: function (value) {
            this.input.value = value
        }
    }

    return PickerField;
}));