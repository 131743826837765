(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('EventBus', ['Vue'], factory);
    } else {
        // Browser globals
        root.EventBus = factory(root.Vue);
    }
}(this, function (Vue) {
    'use strict';

    if (typeof (Vue) === 'function') {
        return new Vue({
            methods: {
                dispatch: function (name, data) {

                    var init = {
                        bubbles: true,
                        cancelable: false,
                        detail: data
                    }

                    var newEvent;
                    try {
                        newEvent = new CustomEvent(name, init)
                    } catch (err) {
                        newEvent = document.createEvent('CustomEvent')
                        newEvent.initCustomEvent(
                            name,
                            init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                            init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                            init ? init.detail : null
                        );
                    }
                    document.documentElement.dispatchEvent(newEvent);
                    this.$emit(name, data);
                }
            }
        });
    }
}));