(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ContestApp', ['Vue', 'VotingContest', 'EventBus'], factory);
    } else {
        // Browser globals
        root.ContestApp = factory(root.Vue, root.VotingContest, root.EventBus);
    }
}(this, function (Vue, VotingContest, EventBus) {
    'use strict';

    function configObjectFromMeta(prefix, container) {
        var config = {}

        var meta = container.querySelectorAll('meta[itemprop^="' + prefix + ':"]');

        Array.prototype.forEach.apply(meta, [function (el) {
            var key = el.getAttribute('itemprop').split(':');
            var value = el.getAttribute('content');
            config[key[1]] = value;
        }]);

        return config;
    }

    function App(element) {
        this.container = element;

        var canvoteMeta = this.container.querySelector('meta[itemprop="canvote"]');

        var debug = this.container.querySelector('meta[itemprop="debug"]') && true;
        var canvote = canvoteMeta && canvoteMeta.getAttribute('content') == 'true';
        var formConfig = configObjectFromMeta('formConfig', this.container);
        var endpoints = configObjectFromMeta('endpoints', this.container);
        var links = configObjectFromMeta('links', this.container);
        var recaptcha = configObjectFromMeta('recaptcha', this.container);

        this.instance = new Vue({
            el: this.container,
            name: 'ContestApp',
            components: { VotingContest: VotingContest },
            template: '<VotingContest v-bind="config" @dismiss="onDismiss" @open="onOpen" @user="onUserUpdated" />',
            methods: {
                onUserUpdated: function (data) {
                    EventBus.dispatch('voting-contest:user', data)
                },
                onDismiss: function (data) {
                    EventBus.dispatch('voting-contest:dismiss-modal', data)
                },
                onOpen: function (data) {
                    EventBus.dispatch('voting-contest:open-modal', data)
                }
            },
            data: function () {
                return {
                    config: {
                        debug: debug,
                        canvote: canvote,
                        formConfig: formConfig,
                        endpoints: endpoints,
                        links: links,
                        recaptcha: recaptcha
                    }
                }
            }
        });
    }

    return App;
}));
